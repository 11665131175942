import * as React from 'react'
import { Component } from 'react'
import { Select, Row, Col, Modal, Form, Input } from "antd-min";
import { GLFormComponentProps, GLForm, GLGlobal } from "gl-commonui";
import { UserModel, UserRoleModel, UserInvitationModel } from '../../../../service/users/model';
import { GSAdminLocale } from '../../../../locales/localeid';

export interface InvitationsOperationProps {
    model?: UserModel
    onSubmit?: (value: UserModel) => void
    onCancel?: () => void
}
interface InvitationsOperationStates {
    defaultInvitationType?: string
    invitationTypeOptions?: JSX.Element[] | null
    visible?: boolean
    record?: UserRoleModel
    regionOptions?: any
    roleOptions: JSX.Element[] | null
}
export abstract class InvitationsOperationModal<TProps=any, TStates=any> extends Component<TProps & InvitationsOperationProps & GLFormComponentProps, TStates & InvitationsOperationStates> {
    protected modalTitle?: string;
    footer?: JSX.Element[] | null
    width?: any
    constructor(props, context) {
        super(props, context);
    }

    initState(selfState = { visible: false } as InvitationsOperationStates & TStates) {
        return { ...selfState as object } as InvitationsOperationStates & TStates;
    }

    renderOption(item, index) {
        return <Select.Option key={index} value={index}>{item}</Select.Option>
    }
    validateFields() {
        let variables = null;
        this.props.form.validateFieldsAndScroll(null, {}, (err, values: UserInvitationModel) => {
            if (!err) {
                this.props.model && (values.id = this.props.model.id)
                variables = this.props.form.getFieldsValue();
            }
        });
        return variables;
    }
    onOk() {
        const variables = this.validateFields();
        if (variables !== null && this.props.onSubmit) {
            this.setState({ visible: false });
            this.props.onSubmit(variables);
        }
    }
    onCancel() {
        this.setState({ visible: false });
        this.props.onCancel && this.props.onCancel();
    }
    abstract renderContent()
    render() {
        const props = {
            visible: this.state.visible,
            destroyOnClose: true,
            title: this.modalTitle,
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            width: this.width || "780px",
            footer: this.footer
        };
        return <Modal {...props}>
            {this.renderContent()}
        </Modal>;
    }
}

@GLForm.create()
export class DeleteModal extends InvitationsOperationModal {
    constructor(props, context) {
        super(props, context);
        this.state = this.initState();
        this.modalTitle = GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationDelete });
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        if (this.props.visible != nextProps.visible && nextProps.visible) {
            this.props.form && this.props.form.resetFields();
        }
    };
    
    renderContent() {}

    render() {
        const props = {
            visible: this.props.visible,
            destroyOnClose: true,
            title: this.props.title? this.props.title : this.modalTitle,
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: "480px",
        };
        return <Modal {...props}>
            {this.props.renderContent()}
        </Modal>;
    }
}