import * as React from 'react'
import { Component } from 'react';
import { Table, Icon, Row, Col } from "antd-min";
import {
    GLGlobal,
    PaginationParams,
    InvitationType,
    MessageHelper,
    NotificationType,
    GLAction,
    GLLocale
} from "gl-commonui";
import { UserPendingModel, UserPendingProps, IUserService } from '../../../service/users/index';
import { GSAdminLocale } from '../../../locales/localeid';
import { lazyInject, TYPES, CONSTS, InvitationHelper, GSAdminAction, fmtMsg, InvitationChannel } from '../../../util/index';
import { DeleteModal } from './components/invitationsoperation';
import { IInvitationService } from '../../../service/admin/acceptinvitation';
import { GLInvitationModal } from '../../../components/admin/gl-invitationmodal';
import { Link } from 'react-router-dom';
import { AdminPathConfig as PathConfig } from '../../../config/pathconfig';
import {MainTitle, WijmoGrid} from '@app/components';
const { Grid, Column } = WijmoGrid;

export interface InvitationsProps { }
interface InvitationsStates {
    tableLayout?: any
    visible?: boolean
    disabled?: boolean
    loading?: boolean
    record?: any
    deletionVisible?: boolean
}

export class InvitationsPage extends Component<InvitationsProps, InvitationsStates> {
    @lazyInject(TYPES.IInvitationService)
    invitationservice: IInvitationService
    @lazyInject(TYPES.IUserService)
    service: IUserService
    clickedBtns = []
    formatMessage = GLGlobal.intl.formatMessage;
    pagination: PaginationParams = new PaginationParams(1, 20)
    tableLayout: any = {
        columns: [
            {
                title: this.formatMessage({ id: GSAdminLocale.UserPendingName }),
                dataIndex: UserPendingProps.name,
                width: "1.5*"
            }, {
                title: this.formatMessage({ id: GSAdminLocale.UserPendingEmailPhone }),
                dataIndex: UserPendingProps.email,
                width: "2*",
            }, {
                title: this.formatMessage({ id: GSAdminLocale.UserPendingType }),
                dataIndex: UserPendingProps.type,
                width: "1.5*"
            }, {
                title: this.formatMessage({ id: GSAdminLocale.UserPendingCreationDate }),
                dataIndex: UserPendingProps.creationDate,
                width: "1.5*"
            }, {
                title: "",
                width: "1*",
                className: "tableAction",
                render: (text, record, index) => {
                    return <span onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>{this.renderOperations(text, record, index)}</span>;
                }
            }
        ],
        bordered: false,
        onChange: this.handleTablePageChange.bind(this),
        onRow: (record: UserPendingModel, index) => {
        }
    };
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        this.setTableLayout();
    }

    setTableLoading(isLoading: boolean = true) {
        this.tableLayout.loading = isLoading;
        this.setState({ loading: isLoading });
    }

    handleTablePageChange(pagination: any) {
        this.pagination.current = pagination.current;
        this.setTableLayout();
    }

    formatDate(createDate) {
        let date = new Date(createDate),
            month = createDate.split("-")[1],
            dateArr = date.toString().split(" ");

        return dateArr[3] + "/" + month + "/" + dateArr[2] + ' ' + dateArr[4];
    }

    setTableLayout() {
        this.setTableLoading();
        this.invitationservice.getPendingUsers({
            invitationCodeTypes: [
                InvitationType.classTeacher,
                InvitationType.campusAdmin,
                InvitationType.schoolAdmin,
                InvitationType.accountManager,
                InvitationType.regionAdmin,
                InvitationType.trainingAdmin,
                InvitationType.trainer,
                InvitationType.globalHead,
                InvitationType.systemAdmin
            ]
        }).then(data => {
            this.tableLayout.itemsSource = data.map(item => {
                item.type = CONSTS.InvitationType[item.invitationType.toString()];
                item.creationDate = this.formatDate(item.creationDate);
                item.email = item.email && item.email.length ? item.email : item.phone;
                return item;
            });
            this.pagination.total = data.length;
            this.tableLayout.pagination = this.pagination;
            this.tableLayout.paginationSize = this.pagination.pageSize;
            this.tableLayout.paginationTotal = this.pagination.total;
            this.setState({ tableLayout: this.tableLayout });
            this.setTableLoading(false);
        });
    }

    renderOperations(text, record, index) {
        let clicked = this.clickedBtns.indexOf(record.id) > -1 ? "clicked" : "";
        return [record.invitationChannel == InvitationChannel.Email ?
             <Icon type="mail" title={this.formatMessage({ id: GSAdminLocale.InvitationSendEmailTip })} className={clicked + " action"} key={0} onClick={(value) => this.resendEmail(record)} /> :
             <Icon type="message" title={this.formatMessage({ id: GSAdminLocale.InvitationSendSMSTip })} className={clicked + " action"} key={0} onClick={(value) => this.resendEmail(record)} />,
        <span title={this.formatMessage({ id: GSAdminLocale.InvitationRemoveTip })} key={1} className="icon-delete action" onClick={() => this.setState({ deletionVisible: true, record: record })} />];
    }

    resendEmail(record) {
        //this.setTableLoading();
        this.clickedBtns.push(record.id);
        const message = record.invitationChannel == InvitationChannel.Email ?
            this.formatMessage({id: GSAdminLocale.InvitationResendSuccess}) :
            this.formatMessage({id: GSAdminLocale.InvitationResendMessageSuccess});
        this.invitationservice.sendInvitationMail({ id: record.id })
            .then(data => {
                if (data.success) {
                    MessageHelper.Message(NotificationType.Success, message);
                }
                //this.setTableLoading(false);
            }).catch(error => {
                //this.setTableLoading(false);
            });
    }

    removePendingUser() {
        const message = this.formatMessage({ id: GSAdminLocale.InvitationRemovePrompt }, { name: 'user' });
        return <DeleteModal title={this.formatMessage({ id: GSAdminLocale.InvitationRemoveTip })} visible={this.state.deletionVisible} onSubmit={(value) => {
            this.invitationservice.delete(this.state.record.id).then(data => {
                this.setTableLayout();
                this.setState({ deletionVisible: false });
            });
        }} onCancel={() => { this.setState({ deletionVisible: false }); }}
            renderContent={() => { return message }} />;
    }

    showInviteModal() {
        const params = {
            title: this.formatMessage({id: GSAdminLocale.InvitationTitle}),
            invitationtype: [InvitationType.globalHead, InvitationType.systemAdmin, InvitationType.trainingAdmin],
            emailCaption: "registration.form.emailphonetext"
        }
        return <GLInvitationModal enablePhoneRegistration={true} visible={this.state.visible} disabled={this.state.disabled} modalparams={params} onSubmit={value => {
            this.setState({ disabled: true });
            const successMessage = value.invitationChannel == InvitationChannel.Email ?
                GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSendSuccess }) :
                GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSendMessageSuccess });
            const failedMessage = value.invitationChannel == InvitationChannel.Email ?
                GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveFail }) :
                GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveMessageFail });
            this.invitationservice.generateInvitationCode({
                senderId: GLGlobal.loginInfo().profile.sub,
                invitationType: value.invitationtype,
                templateId: value.template,
                name: value.name,
                email: value.email,
                phoneCountryCode: value.countryCode,
                invitationChannel: value.invitationChannel
            }).then(data => {
                if (!data.sendEmailSuccess && !data.sendSMSSuccess) {
                    InvitationHelper.sendEmailFail(failedMessage);
                    return;
                }
                if (value.callBack) { value.callBack(true); }
                this.setTableLayout();
                this.setState({ visible: false });
                InvitationHelper.sendEmailSuccess(successMessage);
            }).catch((error) => {
                //console.log(error);
                this.setState({ disabled: false });
                this.setTableLoading(false);
            });
        }} onCancel={() => { this.setState({ visible: false }) }} />;
    }

    render() {
        return <div className='invitations content-layout'>
            <MainTitle plain={fmtMsg({ id: GSAdminLocale.InvitationInvitations })} />
            <div className="page-content">
                <Row className='rightControl'>
                    <GLAction action={GSAdminAction.ListUser}>
                        <Link to={{ pathname: PathConfig.Users }} style={{ marginRight: "15px" }}>
                            <Icon type='team' className="actionicon"></Icon>
                            <span>{this.formatMessage({id: GSAdminLocale.InvitationUsers})}</span>
                        </Link>
                    </GLAction>
                    <GLAction action={GSAdminAction.CreateInvitaion}>
                        <a onClick={() => { this.setState({ visible: true }); }}>
                            <Icon type='plus-circle' className="actionicon"></Icon>
                            <span>{this.formatMessage({id: GSAdminLocale.InvitationNew})}</span>
                        </a>
                    </GLAction>
                </Row>
                <Row>
                    <Col>
                        <Grid
                            {...this.state.tableLayout}
                            paginationShowTotal={(total, range) =>
                                fmtMsg(
                                    { id: GLLocale.Pagination },
                                    { from: range[0], to: range[1], total }
                                )
                            }
                        >
                            {
                                this.state.tableLayout && this.state.tableLayout.columns.map(column => {
                                    return <Column
                                        header={column.title}
                                        binding={column.dataIndex}
                                        render={column.render}
                                        key={column.key}
                                        width={column.width}
                                        cssClass={column.className}
                                    />
                                })
                            }
                        </Grid>
                    </Col>
                </Row>
            </div>
            {this.showInviteModal()}
            {this.removePendingUser()}
        </div>;
    }
}
